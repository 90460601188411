import logo from "./logo.svg";
import "./App.css";
import { HeroBullets } from "./Hero";

function App() {
  return (
    <>
      <HeroBullets />
    </>
  );
}

export default App;
